article {
  width: auto;
  margin: auto;
  max-width: 920px;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.desc {
  padding: 20px;
}
.desc h1 {
  font-size: 3rem;
  text-align: center;
}
.desc img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  max-height: 400px;
  max-width: 100%;
}
.desc p {
  font-size: large;
}

#gallery {
  display: flex;
  justify-content: center;
  padding: 50px 0 50px;
  background-color: #f9f9f9;
  flex-wrap: wrap;
}

#payment {
  display: flex;
  justify-content: center;
}
